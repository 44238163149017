import {createApp} from 'vue'
import App from './App.vue'
import router from '@/router'
import 'element-plus/theme-chalk/index.css'
import ElementPlus from 'element-plus'
import "@/assets/css/theme/theme-all.css";
import axios from '@/axios/index'
import appStore from '@/store/index'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import naive from 'naive-ui'
// import {useRouter} from 'vue-router'


const app = createApp(App)

app.use(router);
app.use(appStore);
app.use(ElementPlus);

app.use(naive);





for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.mount('#app')
app.config.globalProperties.$axios = axios;
// app.config.globalProperties.$useRouter=useRouter;