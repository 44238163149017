import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/userLogin'
    },
    {
        path: '/mainView',
        name: 'mainView',
        component: () => import(/* webpackChunkName: "introduce" */ '../components/MainView.vue'),
        redirect:'/mainConsole',
        children: [
            {
                path: '/mainConsole',
                name: 'mainConsole',
                component: () => import(/* webpackChunkName: "account" */ '../components/MainConsole.vue')
            },
            {
                path: '/projectPage',
                name: 'projectPage',
                component: () => import(/* webpackChunkName: "account" */ '../components/project/ProjectPage.vue')
            }
        ]
    },
    {
        path: '/userLogin',
        name: 'userLogin',
        component: () => import(/* webpackChunkName: "account" */ '../components/UserLogin.vue')
    }
    ,

]


const router = createRouter({
    // hash模式：createWebHashHistory，history模式：createWebHistory
    history: createWebHashHistory(),
    routes: routes,
})

export default router