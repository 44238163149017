import axios from "axios";
// import httpCode from "@/const/httpCode";
// import {ElMessage} from "element-plus";
// import "nprogress/nprogress.css";

const httpRequest = axios.create({
    // 状态码校验范围
    validateStatus: function (status) {
        return status >= 200 && status <= 500;
    },
    //请求后端数据的基本地址，自定义
    baseUrl: 'http://localhost:8080',
    timeout: 30000,
    withCredentials: true,
})

httpRequest.interceptors.request.use(
    config => {
        return config;
    }, error => {
        return Promise.reject(error);
    }
)
httpRequest.interceptors.response.use(

)

// // NProgress Configuration
// NProgress.configure({
//     showSpinner: false
// });
//
// // HTTP request拦截
// axios.interceptors.request.use(
//     config => {
//         // NProgress.start(); // start progress bar
//         // const isToken = (config.headers || {}).isToken === false;
//         // let token = store.getters.access_token;
//         // if (token && !isToken) {
//         //     config.headers["Authorization"] = "Bearer " + token; // token
//         // }
//         // // headers中配置serialize为true开启序列化
//         // if (config.methods === "post" && config.headers.serialize) {
//         //     config.data = serialize(config.data);
//         //     delete config.data.serialize;
//         // }
//         //
//         // // 处理get 请求的数组 springmvc 可以处理
//         // if (config.method === "get") {
//         //     config.paramsSerializer = function (params) {
//         //         return qs.stringify(params, {arrayFormat: "repeat"});
//         //     };
//         // }
//
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );
//
// // HTTP response拦截
// axios.interceptors.response.use(
//     res => {
//         const status = Number(res.status) || 200;
//         const message = res.data.msg || errorCode[status] || errorCode["default"];
//         //
//         // // 后台定义 424 针对令牌过去的特殊响应码
//         // if (status === 424) {
//         //     MessageBox.confirm("令牌状态已过期，请点击重新登录", "系统提示", {
//         //         confirmButtonText: "重新登录",
//         //         cancelButtonText: "取消",
//         //         type: "warning"
//         //     })
//         //         .then(() => {
//         //             store.dispatch("LogOut").then(() => {
//         //                 // 刷新登录页面，避免多次弹框
//         //                 window.location.reload();
//         //             });
//         //         })
//         //         .catch(() => {
//         //         });
//         //     return;
//         // }
//
//         if (status !== 200 || res.data.code === 1) {
//             Message({
//                 message: message,
//                 type: "error"
//             });
//             return Promise.reject(new Error(message));
//         }
//
//         return res;
//     },
//     error => {
//         return Promise.reject(new Error(error));
//     }
// );

export default httpRequest;
