<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {},
  mounted() {
  },
  methods: {},

}
</script>

<style>
html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  /*overflow-y: auto !important;*/
  padding-right: 0 !important;
  margin: 0;
  padding: 0;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
